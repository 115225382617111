$bg-header: #e0e5e9;
$bg-employee: #f3f4f5; //#e4e8f4;
$bg-entity: #e3e7f2;
$bg-gap: #e9edef; // #f8faff;
$bg-skill: #e9edef; //#e9edef;
$bg-department: #dde0ea;
$body-bg: #f3f4f5;
$td-bg: #b0bec5;

.matrix-selector {
	width: 18rem;
}

.matrix {
	max-width: 100%;
	max-height: calc(100vh - 70px);
	overflow: scroll;
	position: relative;
	margin-top: -1px;
	// min-height: 20rem;
	table {
		position: relative;
		border-collapse: collapse;
		overflow-x: unset;
		width: auto;
	}
	td,
	th {
		padding: 0.25rem 0.25rem;
		// background-color: $body-bg;
	}
	// tr:hover th,
	tr:hover td {
		background-color: #fffde7 !important;
	}
	td.level {
		background-color: $bg-skill;
	}
	thead th {
		position: -webkit-sticky;
		/* for Safari */
		position: sticky;
		top: 0;
		/* background: whitesmoke; */
		padding: 0rem 0.1rem;
		background-color: $body-bg;
	}
	thead th:first-child {
		left: 0;
		z-index: 1;
	}
	tbody th {
		position: -webkit-sticky;
		position: sticky;
		left: 0;
	}
	.table th,
	.table td {
		border: none;
	}
	.selector-cell {
		max-width: 18rem;
		min-width: 18rem;
		width: 18rem;
		padding: 1rem;
		min-height: 16rem;
		background-color: white;
		.selector {
			// background-color: $bg-gap;
			// position: absolute;
			// left: 0rem;
			// top: 0rem;
			// right: 0.25rem;
			// bottom: 0.5rem;
			border-bottom-right-radius: 1rem;
		}
		.legend {
			position: absolute;
			right: 0px;
			bottom: -2px;
			.btn {
				color: blue;
				.icon {
					font-size: 1.6rem;
				}
			}
		}
	}

	.entity-row,
	.department-row {
		background-color: $body-bg;
		font-weight: 500;
		padding: 0rem 0rem 0.1rem 0.1rem;
		z-index: 1;
	}
	.department {
		background-color: $bg-department;
		padding: 0.15rem;
		font-size: 1rem;
	}
	.entity {
		background-color: $bg-entity;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		font-size: 0.85rem;
	}

	.col-gap {
		width: 5rem;
		min-width: 5rem;
		height: 5rem;
		.gap-heading {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 1rem;
			.col {
				padding-bottom: 0.5rem;
				min-width: 0;
			}
			.rotate-90 {
				width: 5rem;
				height: 5rem;
			}
		}
		.gap-result {
			position: absolute;
			left: 0;
			right: 0;
			top: 1.5rem;
		}
		// border-bottom: 1px solid rgb(242, 242, 242) !important;
	}
	.skill-gap {
		background-color: $bg-gap;
		z-index: 1;
		.col {
			font-size: 0.8rem;
			font-weight: 600;
		}
	}

	.table thead th {
		border: none;
	}
	.employee-w {
		max-width: 2.6em;
		min-width: 2.6rem;
		padding-left: 0.1rem;
		padding-right: 0.1rem;
		.employee-header {
			margin: auto;
			background-color: $bg-employee;
			border-bottom-left-radius: 1.25rem;
			border-bottom-right-radius: 1.25rem;
			padding-bottom: 0.2rem;
			.employee-vertical {
				height: 7rem;
				width: 7rem;
				text-align: initial;
				font-weight: 300 !important;
				transform-origin: 3.6rem 3.5rem !important;
			}
		}
	}

	.blank-header {
		height: 14rem;
	}

	.gap-vertical {
		height: 9.6rem;
		width: 9.6rem;
		text-align: initial;
		font-weight: 300 !important;
	}

	.rotate-90 {
		-moz-transform: rotate(-90deg);
		-o-transform: rotate(-90deg);
		-webkit-transform: rotate(-90deg);
		transform: rotate(-90deg);
	}
	.rotate90 {
		-moz-transform: rotate(90deg);
		-o-transform: rotate(90deg);
		-webkit-transform: rotate(90deg);
		transform: rotate(90deg);
	}
	.rounded-left {
		border-top-left-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;
	}
	.bg-header {
		background-color: $bg-header !important;
	}

	.bg-gap {
		background-color: $bg-gap !important// #cad2d8 !important;
	}

	.sticky-cell {
		position: -webkit-sticky;
		position: -webkit-sticky;
		position: sticky;
	}
	.skill {
		background-color: #152e4d17;
		padding: 0.35rem 0.35rem;
		height: 2.5rem;
		border-bottom: 1px solid white;
	}
	.cat {
		width: 2rem;
		background-color: $bg-header;
		z-index: 2;
	}
	// .cat-vertical1 {
	// 	transform-origin: 0 100%;
	// 	transform: rotate(-90deg);
	// 	white-space: nowrap;
	// 	position: absolute;
	// 	left: 100%;
	// }
	.cat-vertical {
		position: absolute;
		text-align: center;
		bottom: 0;
		top: 0;
		left: 0;
		writing-mode: vertical-rl;
		text-orientation: mixed;
		cursor: default;
	}
	.sticky-left-1 {
		left: 0;
		z-index: 2;
	}
	.sticky-left-2 {
		left: 2rem;
		max-width: 14rem;
		padding-top: 0.05rem;
		padding-bottom: 0.05rem;
		padding-right: 0.1rem;
		padding-left: 0.05rem;
		z-index: 2;
		background-color: white;
	}
	.sticky-top-1 {
		top: 0;
		height: 2rem;
	}
	.sticky-top-2 {
		top: 0rem;
		z-index: 2 !important;
	}
	.sticky-top-3 {
		top: 1.7rem;
		z-index: 2 !important;
	}
	.stay-top {
		z-index: 2 !important;
	}
	.gap-num {
		height: 2rem;
	}
	.pl-t1 {
		padding-left: 1px;
	}
	.pr-t1 {
		padding-right: 1px;
	}
	.level-cell {
		width: 2rem;
		height: 2rem;
		padding: 0.2rem;
		border-radius: 50%;
	}
	.level-cell:active,
	.level-cell:focus {
		background-color: rgba(0, 0, 0, 0.1);
	}
}
