$headerHeight: 70px;

.side-card {
	border: none;
	background-color: #edeff0;
	box-shadow: none;
	border-radius: 0;
	margin-bottom: 0;
	height: 100%;
	align-self: stretch !important;
	// margin-left: -36px;
}

.vh-100-h {
	height: calc(100vh - 10rem);
}

.btn-select-group {
	padding: 0.5rem 1rem;
	background-color: #152e4d0d;
	.btn-select {
		border-radius: 1rem;
		// min-width: 4rem;
		white-space: nowrap;
		// padding: 0.125rem 0.5rem;
		&.active {
			color: #1967d2 !important;
			background: #e8f0fe !important;
			border: 1px solid #1a73e8 !important;
		}
	}
	:not(:last-child) {
		margin-right: 0.5rem;
	}
}
.btn-group {
	// padding: 0.5rem 1rem;
	// background-color: #152e4d0d;
	.btn {
		// border-radius: 1rem;
		// min-width: 4rem;
		white-space: nowrap;
		// padding: 0.125rem 0.5rem;
		&.active {
			color: #1967d2 !important;
			background: #e8f0fe !important;
			border: 1px solid #1a73e8 !important;
		}
	}
}

input[type="search"]::-webkit-search-cancel-button {
	-webkit-appearance: searchfield-cancel-button;
}

.switch-wrapper {
	position: relative;
}

.switch-wrapper > div {
	position: absolute;
	left: 0;
	right: 0;
}

.transition {
	position: absolute;
	width: 100%;
}
