//
// user.scss
// Use this to write your custom SCSS
//
.r-box {
	background-color: white;
	border-radius: 1rem;
	padding: 1.5rem;
	box-shadow: 0 0.05rem 0.125rem rgba(0, 0, 0, 0.075) !important;
}
.app-icon {
	color: $gray-600;
}

.text-center {
	text-align: center !important;
}
.c-pointer {
	cursor: pointer !important;
}
.navbar-brand {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	font-family: sans-serif;
	font-weight: 600;
	&.brand-client {
		font-size: 1.4rem;
	}
	&.matrix {
		img {
			width: 2.5rem !important;
			margin-right: 0.5rem;
		}
		padding-left: 0;
		padding-right: 0;
	}
}
.navbar.sticky-top {
	padding-left: 1rem;
	padding-right: 1rem;
}

.card.selected {
	border-color: blue;
}

.form-control {
	&-clear {
		border-color: transparent;
	}
}

.card {
	&[class*="-soft"] {
		.card-header {
			border-bottom-color: rgba(255, 255, 255, 0.75);
		}
		.card-footer {
			border-top-color: rgba(255, 255, 255, 0.75);
		}
	}
}
.btn-disabled {
	opacity: 0.4;
}
.btn-white:disabled:hover,
.btn-white:disabled:focus {
	border-color: transparent;
}

.line-clamp {
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.w-fit {
	width: fit-content;
}

.word-break-all {
	word-break: break-all;
}

.top-0 {
	top: 0;
}