.move {
	&-enter {
		opacity: 0.01;
		transform: translateX(0px);
	}

	&-enter-active {
		opacity: 1;
		transform: translate(0, 0);
		transition: all 0.5s ease-in;
	}

	&-exit {
		opacity: 1;
		transform: translate(0, 0);
	}

	&-exit-active {
		opacity: 0.01;
		transform: translateX(200px);
		transition: all 0.5s ease-in;
	}
}
.moveback {
	&-enter {
		opacity: 0.01;
		transform: translateX(-200px);
	}

	&-enter-active {
		opacity: 1;
		transform: translate(0, 0);
		transition: all 0.5s ease-in;
	}

	&-exit {
		opacity: 1;
		transform: translate(0, 0);
	}

	&-exit-active {
		opacity: 0.01;
		transform: translateX(0);
		transition: all 0.5s ease-in;
	}
}

.slide1 {
	&-enter,
	&-exit {
		transition: all 0.15s ease;
	}

	&-enter-active,
	&-exit-active {
		transform: translateX(-100px);
	}
}
