.report-popover-continer {
	// overflow-y: hidden;

	.report-body {
		// height: 50vh;
		max-width: 34rem;
		max-height: 26rem;
		min-height: 24rem;
		overflow-y: auto;
		overflow-x:"none"
	}
}
