.modal-pdf-viewer {
    max-width: 90%; 
    top: 0rem;
    &.fullscreen {
        max-width: 98%;
        height: 95%;
        margin: 1rem auto;
        .modal-content {
            height: 100%;
        }
    }
   .modal-body{
       padding: 0;
   }
}