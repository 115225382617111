.table {
	margin-bottom: 0;
}
.table-fix-h {
	.table {
		thead th {
			position: sticky;
			top: 0;
			z-index: 5;
		}
	}
	max-height: calc(100vh - 14rem);
	overflow-y: auto;
}

.table {
	thead th {
		text-transform: initial;
		border-bottom: 2px solid #dee2e6; // #cfd8dc;
		color: initial;
		font-weight: normal;
		padding: 1rem 0.75rem;
	}
	&.align-base-line {
		thead th,
		tbody th,
		tbody td {
			vertical-align: baseline;
		}
	}
	&.align-top {
		thead th,
		tbody th,
		tbody td {
			vertical-align: top;
		}
	}
}

.table td {
	padding: 0.25rem 0.75rem;
	&.align-top {
		vertical-align: top;
	}
	&.align-base-line {
		vertical-align: baseline;
	}
}

.table-sm {
	thead th {
		font-size: 0.75rem;
		padding: 0.5rem 0.75rem;
	}
	tbody {
		td {
			font-size: $font-size-base;
		}
	}
}

.table.no-top-border th,
.card-view .table th {
	border-top: none;
}
.table.table-body-white {
	th {
		border-top: none;
		background-color: transparent !important;
	}
	td {
		background-color: white !important;
	}
}
.table th.w-25,
.table td.w-25 {
	width: 25rem !important;
	max-width: 25rem;
}
.table th.w-20,
.table td.w-20 {
	width: 20rem !important;
	max-width: 20rem;
}
.table th.w-15,
.table td.w-15 {
	width: 15rem !important;
	max-width: 15rem;
}

.table th.w-12,
.table td.w-12 {
	max-width: 12rem;
	width: 12rem !important;
}

.table th.w-10,
.table td.w-10 {
	max-width: 10rem;
	width: 10rem !important;
}

.table th.w-8,
.table td.w-8 {
	max-width: 8rem;
	width: 8rem !important;
}
table th.w-7,
.table td.w-7 {
	max-width: 7rem;
	width: 7rem !important;
}
.table th.w-6,
.table td.w-6 {
	max-width: 6rem;
	width: 6rem !important;
}

.table th.w-5,
.table td.w-5 {
	max-width: 5rem;
	width: 5rem !important;
}

.table th.w-4,
.table td.w-4 {
	max-width: 4rem;
	width: 4rem !important;
}

.table th.w-3,
.table td.w-3 {
	max-width: 3rem;
	width: 3rem !important;
}

.table th.w-2,
.table td.w-2 {
	max-width: 2rem;
	width: 2rem !important;
}

.table th.w-1,
.table td.w-1 {
	max-width: 1rem;
	width: 1rem !important;
}

$sizes: 1, 2, 3;
@each $size in $sizes {
	.table th.w-#{$size},
	.table td.w-#{$size} {
		max-width: #{$size}rem;
		width: #{$size}rem;
	}
}

.table th.w-date,
.table td.w-date {
	max-width: 6rem;
}

.table th.skill-name,
.table td.skill-name {
	max-width: 25rem;
	width: 25rem;
}
.table tr td.wrap-text {
	white-space: pre-line !important;
}
.table th.skill-level,
.table td.skill-level {
	max-width: 8rem;
	width: 8rem;
}
