.text-gray-9 {
	color: $gray-900;
}
.text-gray-8 {
	color: $gray-800;
}
.text-gray-7 {
	color: $gray-700;
}
.text-gray-5 {
	color: $gray-500;
}
.text-gray-4 {
	color: $gray-400;
}
.text-gray-3 {
	color: $gray-300;
}
.bg-1 {
	background-color: #f2f3f4;
}
.bg-2 {
	background-color: #e5e8ee; // #e2e5e8; //#eceff1;
}
.bg-3 {
	background-color: #eceff1;
}
.bg-4 {
	background-color: #e2e6e6;
}
.bg-1-trans {
	background-color: #152e4d0a !important;
}
.bg-5 {
	background-color: #e8eaed !important;
}

.mr--1 {
	margin-right: -0.25rem;
}
.mr--2 {
	margin-right: -0.5rem;
}
.mr--3 {
	margin-right: -1rem;
}
.mb--1 {
	margin-bottom: -0.25rem;
}
.mb--2 {
	margin-bottom: -0.5rem;
}
.mb--3 {
	margin-bottom: -1rem;
}
.my--3 {
	margin-bottom: -1rem;
	margin-top: -1rem;
}

.ml-n2,
.mx-n2 {
	margin-left: -0.375rem !important;
}
.mb-n3,
.my-n3 {
	margin-bottom: -0.75rem !important;
}
.mt-n3,
.my-n3 {
	margin-top: -0.75rem !important;
}
.ml-n3,
.mx-n3 {
	margin-left: -0.75rem !important;
}
.mr-n3,
.mx-n3 {
	margin-right: -0.75rem !important;
}
.fs-base {
	font-size: $font-size-base !important;
}

.fs-12 {
	font-size: 120% !important;
}
.fs-15 {
	font-size: 150% !important;
}
.fs-2 {
	font-size: 200% !important;
}
.fs-3 {
	font-size: 300% !important;
}
.fs-4 {
	font-size: 300% !important;
}
.fs-5 {
	font-size: 500% !important;
}
.fs-07 {
	font-size: 0.7rem !important;
}
.fs-xs,
.fs-06 {
	font-size: 0.6rem !important;
}
.z0 {
	z-index: 0;
}
.z1 {
	z-index: 1;
}
.z3 {
	z-index: 3;
}

.fw-600 {
	font-weight: 600;
}
.fw-500 {
	font-weight: 500;
}
code {
	color: #3f51b5;
}

.border-dashed {
	border-style: dashed !important;
	border: 1px dashed #2c7be5 !important;
}

.rounded-top-lg {
	border-top-right-radius: 0.5rem;
	border-top-left-radius: 0.5rem;
}

.dropdown .btn {
	padding-top: 0.1875rem !important;
	padding-bottom: 0.1875rem !important;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
