.fs-1 {
	font-size: 1rem !important;
}

.fs-md {
	font-size: 1.25rem !important;
}

.fs-2 {
	font-size: 2rem !important;
}
label {
	color: $gray-800;
	margin-bottom: 0.25rem;
}

.form-sm {
	.form-control {
		padding: 0.25rem 0.5rem;
		height: calc(1.5em + 0.35rem + 2px);
	}
	.form-group {
		margin-bottom: 0.5rem;
	}
	label {
		margin-bottom: 0.1rem;
		font-size: 0.75rem;
	}
}

.form-md {
	.form-group {
		margin-bottom: 0.75rem;
	}
	label {
		margin-bottom: 0.15rem;
	}
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #f1f3f5;
	opacity: 0.85;
}

.form-control.value,
.form-control.readonly {
	background-color: whitesmoke;
	// border: none;
}

.is-invalid ~ .invalid-feedback {
	display: block;
}
.is-invalid.react-select > div:first-child {
	border-color: $danger;
}
