@media print {
	.print_area {
		background-color: white;
		height: 100%;
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		margin: 0;
		padding: 15px;
		font-size: 14px;
		line-height: 18px;
	}
}
@media all {
	.page-break {
		display: none;
	}
}

@media print {
	html,
	body {
		height: initial !important;
		overflow: initial !important;
		//  -webkit-print-color-adjust: exact;
		background-color: white;
	}
	.page-break {
		margin-top: 1rem;
		display: block;
		page-break-before: auto;
	}
	.table-fix-h {
		.table thead th {
			position: initial;
		}
		max-height: initial;
		overflow-y: initial;
	}
}

@page {
	size: auto;
	margin: 10mm;
}
