// slide

.slideRoute {
	&-enter {
		opacity: 0;
		transform: translate(0, -1rem);
	}
	&-enter-active {
		opacity: 1;
		transform: translate(0, 0);
		transition: opacity 0.3s, transform 0.3s;
		transition-property: opacity transform;
		transition-timing-function: cubic-bezier(0.25, 0, 0.5, 1);
		overflow: hidden;
	}
	&-exit-active {
		opacity: 1;
		transform: translate(0, 0);
		transition: opacity 0.3s, transform 0.3s;
		transition-property: opacity transform;
		transition-timing-function: cubic-bezier(0.25, 0, 0.5, 1);
		overflow: hidden;
	}

	&-exit {
		opacity: 0;
		transform: translate(0, 1rem);
	}
}

.slideScale {
	&-enter {
		opacity: 0;
		transform: translateX(-500px) scale(0.6);
	}
	&-enter-active {
		opacity: 1;
		transition: all 400ms ease-out;
		transform: translateX(0) scale(1);
	}
	&-exit {
		opacity: 1;
	}
	&-exit-active {
		opacity: 0;
		transition: all 400ms ease-in;
		transform: translateX(500px) scale(0.6);
	}
}

.slideScaleBack {
	&-enter {
		opacity: 0;
		transform: translateX(500px) scale(1);
	}
	&-enter-active {
		opacity: 1;
		transition: all 400ms ease-out;
		transform: translateX(0) scale(1);
	}
	&-exit {
		opacity: 1;
	}
	&-exit-active {
		opacity: 0;
		transition: all 400ms ease-in;
		transform: translateX(-500px) scale(0.6);
	}
}

.slideIn {
	&-enter {
		opacity: 0;
		transform: translateX(-50%) scale(0.6);
	}
	&-enter-active {
		opacity: 1;
		transition: all 400ms ease-out;
		transform: translateX(0);
	}
	&-exit {
		opacity: 1;
	}
	&-exit-active {
		opacity: 0;
		transition: all 200ms ease-in;
		transform: translateX(20%) scale(0.2);
	}
}

.slideInBack {
	&-enter {
		opacity: 0;
		transform: translateX(50%);
	}
	&-enter-active {
		opacity: 1;
		transition: all 400ms ease-out;
		transform: translateX(0);
	}
	&-exit {
		opacity: 1;
	}
	&-exit-active {
		opacity: 0;
		transition: all 200ms ease-in;
		transform: translateX(-20%);
	}
}

.fade {
	&-enter {
		opacity: 0;
		transition: all 400ms ease-out;
	}

	&-enter-active {
		opacity: 1;
	}

	&-exit {
		opacity: 1;
	}

	&-exit-active {
		opacity: 0;
		transition: all 400ms ease-in;
	}
}
