$headerHeight: 70px;

.page-wrapper {
	padding-top: $headerHeight;
	position: relative;
	text-align: left;
	z-index: 1;
}

.page-header {
	height: 7rem;
	width: 100%;
	z-index: -1;
}

.page-title a {
	color: inherit;
}

.over-header {
	margin-top: -3.5rem;
}

.card-view,
.card-layout {
	min-height: calc(100vh - 9.5rem);
}

.card-view {
	margin-bottom: 1px;
	background-color: white;
	.card-header {
		background-color: initial;
		padding: 0.75rem 1.25rem;
		min-height: 4rem;
	}
}

.min-h-auto {
	min-height: auto !important;
}

.rounded-5 {
	border-radius: 0.5rem !important;
}

.rounded-5-top {
	border-top-left-radius: 0.5rem !important;
	border-top-right-radius: 0.5rem !important;
}
.rounded-5-bottom {
	border-bottom-left-radius: 0.5rem !important;
	border-bottom-right-radius: 0.5rem !important;
}
.rounded-10-top {
	border-top-left-radius: 1rem !important;
	border-top-right-radius: 1rem !important;
}
.rounded-10-bottom {
	border-bottom-left-radius: 1rem !important;
	border-bottom-right-radius: 1rem !important;
}
.rounded-5 .card-header:first-child {
	border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
}

.rounded-75 {
	border-radius: 0.75rem !important;
}

.rounded-10 {
	border-radius: 1rem !important;
}

.rounded-25 {
	border-radius: 2.5rem !important;
}
.rounded-0 {
	border-radius: 0 !important;
}
.min-e-w {
	min-width: 10rem;
}
.pac-container {
	z-index: 1070 !important;
}
.border-dash {
	border-style: dashed !important;
	border-width: 2px !important;
}
.lookup-w {
	width: 34rem !important;
}
.mb-l {
	margin-bottom: 5rem !important;
}

.modal-footer .del {
	margin-right: 0 !important;
}
.vh-100-7,
.vh-100-card {
	height: calc(100vh - 6.5rem);
	overflow-y: auto;
	overflow-x: hidden;
}
.vh-full {
	height: calc(100vh - 62px);
	overflow-y: auto;
}
.card-heading {
	min-height: 7rem;
}

.bw-2 {
	border-width: 0.15rem;
}

.mt--3 {
	margin-top: -1rem;
}
.mt--5 {
	margin-top: -2rem;
}
.mt--6 {
	margin-top: -3rem;
}
.mt--7 {
	margin-top: -3.5rem;
}
.mt--8 {
	margin-top: -4rem;
}
.mt--10 {
	margin-top: -5rem;
}

.r-box {
	background-color: white;
	border-radius: 1rem;
	padding: 1.5rem;
	box-shadow: 0 0.05rem 0.125rem rgba(0, 0, 0, 0.075) !important;
}

.popover-card {
	position: absolute;
	left: 0.5rem;
	right: 0.5rem;
	border-radius: 0.5rem;
	// background-color: #fbfbfb;
	margin-top: -0.5rem;
	z-index: 6;
}
.dropdown-menu .dropdown-item:hover {
	background-color: #00000014;
}
.dropdown-item.active,
.dropdown-item:active {
	color: inherit;
	background-color: #007bff;
}

.dropdown-item {
	padding: 0.5rem 1.5rem;
}
.DayPicker-Day {
	line-height: 1rem;
}
.m-0 .DayPicker-wrapper {
	margin: 0 !important;
	padding-bottom: 0;
}

.navbar-dark {
	.custom-control-label {
		color: $gray-600 !important;
	}
}
