.setup-wizard {
	.wizard-card-body {
		min-height: 20rem;
	}
	.step-done {
		// border-top: 2px solid green;
		a {
			color: indigo !important;
		}
	}
}
