[data-tooltip]:not([data-tooltip=""]) {
	position: relative;
	&::before,
	&::after {
		--scale: 0;
		--arrow-size: 6px;
		--tooltip-color: #222;
		box-sizing: border-box;

		position: absolute;
		top: -0.25rem;
		left: 50%;
		transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
		transition: 150ms transform;
		transition-delay: 250ms;
		transform-origin: bottom center;
	}
	&::before {
		content: attr(data-tooltip);
		color: white;
		padding: 0.5rem;
		border-radius: 0.3rem;
		text-align: center;
		width: max-content;
		max-width: 100%;
		font-size: 0.75rem;
		background: var(--tooltip-color);
		--translate-y: calc(-100% - var(--arrow-size));
	}
	&::after {
		content: "";
		border: var(--arrow-size) solid transparent;
		border-top-color: var(--tooltip-color);
		transform-origin: top center;
		--translate-y: calc(-1 * var(--arrow-size));
	}
	&:hover::before,
	&:hover::after {
		--scale: 1;
	}
}
