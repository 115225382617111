.popover {
	max-width: 45rem !important;
	box-shadow: 0 0.2rem 1rem rgba(18, 38, 63, 0.15) !important;
	font-size: $font-size-base;
	border-color: #e3ebf6;
}

.popover-header {
	display: flex !important;
	justify-content: space-between !important;
	align-items: center;
	padding: 0.25rem 0.25rem 1rem 0.25rem;
}
.popover-footer {
	margin: 1rem -1.5rem -0.8rem -1.5rem;
	border-radius: 0 0 0.45rem 0.45rem;
	background-color: rgb(245, 245, 245);
	padding: 1rem 1.5rem;
}
.modal-body .popover-footer {
	margin-bottom: -1.5rem;
}

#popover-sm {
	.popover-body {
		min-width: 24rem;
		max-width: 100%;
		color: initial;
	}
}
#popover-xs {
	.popover-body {
		min-width: 16rem;
		max-width: 100%;
		color: initial;
	}
}

#popover-md-flush,
#popover-md {
	.popover-body {
		min-width: 32rem;
		max-width: 100%;
		color: initial;
	}
}
#popover-md-flush {
	&.popover {
		padding: 0 !important;
	}
}

#popover-lg-flush,
#popover-lg {
	&.popover {
		max-width: 60rem !important;
	}
	.popover-body {
		min-width: 46rem;
		max-width: 100%;
		color: initial;
	}
}
#popover-lg-flush {
	&.popover {
		padding: 0 !important;
	}
}
#popover-max,#popover-xl-flush {
	&.popover {
		max-width: 85vw !important;
	}
	.popover-body {
		min-width: 65vw;
		max-width: 100%;
		color: initial;
		@media (min-width: 1024px) and (max-width: 1520px) {
		min-width: 75vw;
		}
	}
	
}
#popover-xl-flush {
	&.popover {
		padding: 0 !important;
	}
}
#popover-flush {
	padding: 0;
	.popover-body {
		width: 22rem;
		color: initial;
	}
}

.popover-flush {
	.popover {
		padding: 0;
	}
}

.modal-header .close {
	margin: -1rem -1.5rem -1.5rem auto;
	font-size: 2rem;
}

.popover-body.overflow-50vh {
	overflow-y: auto;
	max-height: 50vh;
  }
  
  @keyframes pulse {
	0% {
	  opacity: 1;
	}
	50% {
	  opacity: 0.5;
	}
	100% {
	  opacity: 1;
	}
  }