// page
.page {
	&-enter {
		opacity: 0;
		transform: scale(0.9);
	}

	&-enter-active {
		opacity: 1;
		transform: scale(1);
		transition: opacity 300ms, transform 300ms;
	}

	&-exit {
		opacity: 1;
		transform: scale(1);
	}
}
// https://medium.com/@ipenywis/slide-page-css-transition-on-react-with-react-router-38373da5e608
/* slideTransition.scss */

//old page exiting, hides it underneath (bottom)

.pageSlider-exit > {
	z-index: 1;
}
//new page entering, show it on top
.pageSlider-enter > {
	z-index: 10;
}
//LEFT transition transform 100% to the right
.pageSlider-enter.left > {
	transform: translate3d(100%, 0, 0);
}
//RIGHT transition transform -100% to the left
.pageSlider-enter.right > {
	transform: translate3d(-100%, 0, 0);
}
//old exit left direction
.pageSlider-exit.left > {
	transform: translate3d(-100%, 0, 0);
}
//old exit right direction
.pageSlider-exit.right > {
	transform: translate3d(100%, 0, 0);
}
//Enter is active (Page has finished transitioning) make it settle down
.pageSlider-enter.pageSlider-enter-active > {
	transform: translate3d(0, 0, 0);
	//Apply all transitions
	transition: all 800ms ease-in-out;
}

.slideLeft-enter {
	// transform: translate3d(100%, 0, 0);
}
.slideLeft-enter.slideLeft-enter-active {
	transform: translate3d(0, 0, 0);
	transition: all 600ms;
}
.slideLeft-exit {
	transform: translate3d(0, 0, 0);
}
.slideLeft-exit.slideLeft-exit-active {
	transform: translate3d(100%, 0, 0);
	transition: all 600ms;
}

.slideRight-enter {
	// transform: translate3d(-100%, 0, 0);
}
.slideRight-enter.slideRight-enter-active {
	transform: translate3d(0, 0, 0);
	transition: all 600ms;
}
.slideRight-exit {
	transform: translate3d(0, 0, 0);
}
.slideRight-exit.slideRight-exit-active {
	transform: translate3d(-100%, 0, 0);
	transition: all 600ms;
}
