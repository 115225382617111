@media (max-width: 992px) {
	.side-collapse {
		position: absolute;
		top: 60px;
		bottom: 0;
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 15px;
		width: 75%;
		height: 100%;
		bottom: 0;
		z-index: 1050;
		left: -75%;
		max-width: 20rem;
		background-color: $navbar-dark-bg;
		transition: left 300ms ease;
		&.show {
			left: 0;
			transition: left 300ms ease-in-out;
		}
	}

	.side-menu-overlay {
		position: absolute;
		top: 60px;
		bottom: 0;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.2);
		z-index: 1049;
	}

	// .side-collapse.collapsing {
	// 	left: -75%;
	// 	transition: height 0s ease;
	// }
}
