.visibility {
	&-enter {
		opacity: 0.01;
	}
	&-enter-active {
		opacity: 0.01;
	}
	&-enter-done {
		opacity: 1;
		transition: opacity 500ms linear;
	}
	&-exit {
		opacity: 1;
	}
	&-exit-active {
		opacity: 0.01;
		transition: opacity 500ms linear;
	}
}
.dialog {
	&-enter {
		opacity: 0.01;
		transform: scale(1.1);
	}

	&-enter-active {
		opacity: 1;
		transform: scale(1);
		transition: all 300ms;
	}

	&-exit {
		opacity: 1;
		transform: scale(1);
	}

	&-exit-active {
		opacity: 0.01;
		transform: scale(1.1);
		transition: all 300ms;
	}
}
