.list-group.active-green {
	.list-group-item.active {
		background-color: $green !important;
		border-color: $green;
	}
}

.list-group.active-border {
	.list-group-item.active {
		color: #1967d2;
		background: #e8f0fe;
		border: 1px solid #1a73e8 !important;
	}
}
.list-group-item-action:hover:not(.active),
.list-group-item-action:focus:not(.active) {
	background-color: #3f51b50a;
	transition: 0.3s ease;
	&.card {
		background-color: #f9f9f8;
	}
}
